const initialTheme = JSON.parse(localStorage.getItem('theme')) || (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark': 'light')

const themeReducer = (state = initialTheme, action) =>{
  switch(action.type){
    case 'CHANGE_THEME':
      const  newtheme = action.payload
      localStorage.setItem('theme', JSON.stringify(newtheme))
      return newtheme;
    case 'DEVICE_THEME':
      if(localStorage.getItem('theme')){
        localStorage.removeItem('theme')
      }
      return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark': 'light';
    default:
      return state

  };
    
}

const initialNotice = {
  status: false,
  text: '',
}
const noticeReducer = (state = initialNotice, action) => {
  switch (action.type) {
      case 'SET_NOTICE_STATUS':
      return {
        ...state,
        status: action.payload,
      };
      case 'SET_NOTICE_TEXT':
      return {
        ...state,
        text: action.payload,
      };
    default:
      return state;
  }
};




export {themeReducer, noticeReducer}

//window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark': 'light'