import { createStore, combineReducers } from 'redux';
import {themeReducer, noticeReducer} from './redux/reducer';

const  rootReducer = combineReducers({
    theme: themeReducer,
    notice: noticeReducer,
})

const store = createStore(rootReducer)

export default store;