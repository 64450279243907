import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import { Provider } from 'react-redux';
import store from './store.js';

//import reportWebVitals from './reportWebVitals';
const body_section = ReactDOM.createRoot(document.getElementById('body'))
body_section.render(<Provider store={store}>
<App/></Provider>)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
