import React, {useEffect, Suspense, lazy} from "react";
import { Link } from "react-router-dom";
import './App.css'
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import {Top, Footer} from "./components/Tools";
import ScrollToTop from "./components/ScrollToTop";
import Lottie from 'lottie-react'
import {setnoticetext, setnoticestatus } from './redux/actions'
import { useDispatch, useSelector} from 'react-redux'
import errorAnimationData from './lottie/error.json'
import loadingAnimationData from './lottie/loading.json'
import successAnimationData from './lottie/success.json'


const  Home = lazy(()=>import ("./components/Home"));
const  Project = lazy(()=> import("./components/Project"));
const About = lazy(()=> import("./components/About"));
const Contact = lazy(()=> import("./components/Contact"));


export default function App(){
    const noticestatus= useSelector(state=> state.notice.status);
    const theme = useSelector(state=> state.theme)
    useEffect(()=>{
        document.body.className = theme
        if(theme === 'light'){
            document.querySelector('meta[name="theme-color"]').setAttribute('content', '#f7f1f0')
        }else{
            document.querySelector('meta[name="theme-color"]').setAttribute('content', '#26333b')
        }
    }, [theme])
    return(
        <>
        <Router>
        <>
        <Top/>
        {noticestatus && <Notice noticestatus={noticestatus}/>}
        <ScrollToTop/>
        <Suspense fallback={<LoadingPage/>}>
        <Routes>
        <Route path="/" exact element={<Home/>}/>
        <Route path="/projects/:projectname" element={ <Project/>}/>
        <Route path="/about"  element={ <About/>} />
        <Route path="/contact"  element={<Contact/>}/>
        <Route path="*" Component={NotFound} />
        </Routes>
        </Suspense>
        <Footer/>
        </>
        </Router>
        </>
    )
}

function LoadingPage(){
    return(
        <div className="pageLoad">
            <img src={`${process.env.PUBLIC_URL}/images/rocket.png`} alt="rocket"/>
            <p>hang on tight..</p>
        </div>
    )
}


function Notice({ noticestatus }){
    const dispatch = useDispatch();
    const noticetext = useSelector(state=> state.notice.text);
    const theme = useSelector(state => state.theme);
    
    const handleClose =()=>{
        dispatch(setnoticetext(''))
        dispatch(setnoticestatus(false))
    }
    return(
        <>
        <div className='noticeCont' style={{backgroundColor: `${theme === 'dark' ? '#26333bf7' : '#e3dcd2f7'}`}}>
            {noticestatus === "loading" && <div id='loadingImage'><Lottie animationData={loadingAnimationData}/></div>}
            {noticestatus ==="success" && <div id='successImage' ><Lottie animationData={successAnimationData} loop={false}/></div>}
            {noticestatus ==="error" && <div id='errorImage'><Lottie animationData={errorAnimationData}/></div>}
            {noticetext && <h3 id='resText'>{noticetext}</h3>}
            {(noticestatus ==="success" || noticestatus === "error") && <button onClick={handleClose}>close</button>}
        </div>
        </>
    )
}

function NotFound(){
    return(
        <div className="notfoundCont" >
            <img src={`${process.env.PUBLIC_URL}/images/notfound.png`} alt="icon"/>
            <h2>404</h2>
            <p><span>oops! page not found</span>... I can't seem to find the page you're looking for. It might have been moved, deleted, or never existed</p>
            <Link to="/" id="notfoundlink">Go back home</Link>
        </div>
    )
}
